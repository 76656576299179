import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react"
import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

export function State({ uf, setUf }) {
  const { register, setValue, formState: { errors } } = useFormContext()
  const ufs = [
    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG",
    "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR",
    "RS", "SC", "SE", "SP", "TO"
  ]
  useEffect(() => {
    setValue('state', uf)
  }, [])

  return <FormControl
    id={"uf"}
    isInvalid={errors.state}
    mr={4}
    maxW={"30%"}
    sx={{
      '@media screen and (max-width: 720px)': {
        maxW: "100%"
      }
    }}

  >
    <FormLabel mb={0}>Estado</FormLabel>
    <Select
      placeholder="Selecione seu estado"
      value={uf}
      {...register("state", {
        required: "Campo obrigatório",
        onChange: (e) => setUf(e.target.value)
      })}
    >
      {
        ufs.map((item) => {
          return <option key={item} value={item}>
            {item}
          </option>
        })
      }
    </Select>
    <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
  </FormControl>
}