import { Text, VStack } from "@chakra-ui/react";
import ShareButtons from "./ShareButtons";

export function CallToActionShare() {
    return (
        <>
            <VStack spacing={6}>
                <Text color={"gray.800"} fontSize={"3xl"} lineHeight={"1.0"}>
                    Compartilhe essa iniciativa
                </Text>
                <Text color={"gray.600"} dangerouslySetInnerHTML={{ __html: 'Use a hashtag <b>#rezeContraOcomunismo</b> nas suas postagens' }} />
                <ShareButtons />
            </VStack>
        </>
    )
}