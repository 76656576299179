import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputGroup,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IfFeatureEnabled, useFeature } from "@growthbook/growthbook-react";
import { useRouter } from "next/router";
import { parseCookies, setCookie } from "nookies";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm } from "react-hook-form";
import { getFrontendAPIClient } from "./../../lib/api";
import { City } from "./Fields/City";
import { Country } from "./Fields/Country";
import { Email } from "./Fields/Email";
import { FirstName } from "./Fields/FirstName";
import { HailMary } from "./Fields/HailMary";
import { LastName } from "./Fields/LastName";
import { State } from "./Fields/State";
import { Zipcode } from "./Fields/Zipcode";

export function BasicData() {
  const methods = useForm();
  const router = useRouter();
  const recaptchaRef = useRef();
  const toast = useToast();
  const { "rezecontraocomunismo@subscriber_data": subscriberDataCookies } =
    parseCookies();
  const subscriberCookies = JSON.parse(subscriberDataCookies || "{}");

  const [hailMary, setHailMary] = useState(50);
  const [uf, setUf] = useState(subscriberCookies.uf || "");
  const [city, setCity] = useState(subscriberCookies.city || "");
  const [country, setCountry] = useState(subscriberCookies.country || "Brazil");

  async function onSubmit(values) {
    //@ts-ignore
    const token = await recaptchaRef.current.executeAsync();
    //@ts-ignore
    recaptchaRef.current.reset();
    const api = getFrontendAPIClient();
    try {
      const subscriberData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        country: values.country,
        uf: uf,
        city: values.city,
        link: router.asPath,
        token,
      };
      const data = await api.post("pray", {
        ...subscriberData,
        hail_mary: hailMary,
      });

      const project = "rezecontraocomunismo";
      setCookie(
        null,
        `${project}@subscriber_data`,
        JSON.stringify(subscriberData),
        {
          maxAge: 60 * 60 * 24 * 30 * 6, // 6 months
          path: "/",
        }
      );
      setCookie(null, `${project}@contact_id`, data.data.contact_id, {
        maxAge: 60 * 60 * 1, //1 hour
        path: "/",
      });

      router.push("/agendar");
    } catch (error) {
      if (error.response?.data.error === undefined) {
        toast({
          title: "Erro ao salvar as Ave-Marias",
          description: error.message,
          status: "error",
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        return;
      }

      if (typeof error.response.data.error === "string") {
        toast({
          title: "Erro ao salvar as Ave-Marias",
          description: error.response.data.error,
          status: "error",
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        return;
      }
      
      error.response.data.error?.map((item) => {
        toast({
          title: "Erro ao salvar as Ave-Marias",
          description: item,
          status: "error",
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
      });
    }
  }

  const addressWithZipcode = useFeature("address-with-zipcode").on;
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <Box mb={4} w={"100%"}>
            <HailMary hailMary={hailMary} setHailMary={setHailMary} />
          </Box>
          <Divider />
          <InputGroup
            gap={2}
            sx={{
              "@media screen and (max-width: 720px)": {
                flexDir: "column",
              },
            }}
          >
            <FirstName value={subscriberCookies.first_name} />
            <LastName value={subscriberCookies.last_name} />
          </InputGroup>
          <Email value={subscriberCookies.email} />
          <IfFeatureEnabled feature='address-with-zipcode'>
            <Zipcode
              value={subscriberCookies.zipcode}
              uf={uf}
              setUf={setUf}
              city={city}
              setCity={setCity}
            />
          </IfFeatureEnabled>
          {!addressWithZipcode && (
            <Country country={country} setCountry={setCountry} />
          )}
          <InputGroup
            gap={2}
            sx={{
              "@media screen and (max-width: 720px)": {
                flexDir: "column",
              },
            }}
          >
            {country === "Brazil" && !addressWithZipcode && (
              <State uf={uf} setUf={setUf} />
            )}

            <City city={city} setCity={setCity} />
          </InputGroup>
          <FormControl id='submit' float='right'>
            <Button
              variant='solid'
              colorScheme='red'
              rightIcon={<ArrowForwardIcon />}
              type={"submit"}
              size={"md"}
              isLoading={methods.formState.isSubmitting}
            >
              Enviar
            </Button>
          </FormControl>
          <Text>
            Nota: Ao assinar, você aceita receber atualizações do IPCO.
            <br />
            Você pode cancelar sua inscrição a qualquer momento.
          </Text>

          <ReCAPTCHA
            sitekey={process?.env?.NEXT_PUBLIC_RECAPTCHA_KEY || ""}
            size='invisible'
            //@ts-ignore
            ref={recaptchaRef}
          />
        </VStack>
      </form>
    </FormProvider>
  );
}
