import {
    Flex,
    HStack,
    LinkBox,
    Popover,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger, useClipboard
} from '@chakra-ui/react';

import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "next-share";
import Image from "next/image";
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getFrontendAPIClient } from "./../../../lib/api";

function formatHastags(hashtags) {
    if (hashtags === undefined) {
        return ['ipco']
    }

    const hashtagsSplitted = hashtags.split('#')
    let indice = 0

    while (indice >= 0) {
        hashtagsSplitted.splice(indice, 1);
        indice = hashtagsSplitted.indexOf('');
    }
    return hashtagsSplitted
}

export default function ShareButtons() {
    let hostname = ''
    if (typeof window !== 'undefined') {
        hostname = window.location.hostname;
    }

    const url = `https://${hostname}`
    const formattedHashtags = formatHastags("#rezeContraOComunismo")
    const { hasCopied, onCopy } = useClipboard(url)
    useEffect(() => {
        if (hasCopied) {
            handleShared()
        }
    }, [hasCopied])
    const router = useRouter()

    const handleShared = async () => {
        const api = getFrontendAPIClient()
        try {
            await api.post('shared', {})
            if (router.pathname === '/compartilhar') {
                router.push('/obrigado')
            }

        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Flex flexDir={"row"} margin={"0 auto"} justifyContent={"space-evenly"}>
            {
                <HStack>
                    <FacebookShareButton
                        url={url}
                        quote={"Reze contra o comunismo. Participe dessa campanha importante"}
                        windowWidth={800}
                        hashtag={`#${formattedHashtags[0]}`}
                        onClick={handleShared}
                    >
                        <Image
                            src="/social-buttons/facebook.svg"
                            width={40}
                            height={40}
                            alt="Compartilhe no Facebook"
                        />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={url}
                        title="Reze contra o comunismo. Participe dessa campanha importante"
                        windowWidth={800}
                        hashtags={formattedHashtags}
                        onClick={handleShared}
                    >
                        <Image
                            src="/social-buttons/twitter.svg"
                            width={40}
                            height={40}
                            alt="Compartilhe no Twitter"
                        />
                    </TwitterShareButton>

                    <WhatsappShareButton
                        url={url}
                        title="Reze contra o comunismo. Participe dessa campanha importante"
                        windowWidth={800}
                        separator=":: "
                        onClick={handleShared}
                    >
                        <Image
                            src="/social-buttons/whatsapp.svg"
                            width={40}
                            height={40}
                            alt="Compartilhe no Whatsapp"
                        />
                    </WhatsappShareButton>

                    <TelegramShareButton
                        url={url}
                        title="Reze contra o comunismo. Participe dessa campanha importante"
                        windowWidth={800}
                        onClick={handleShared}
                    >
                        <Image
                            src="/social-buttons/telegram.svg"
                            width={40}
                            height={40}
                            alt="Compartilhe no Telegram"
                        />
                    </TelegramShareButton>

                    <EmailShareButton
                        url={url}
                        subject="Reze contra o comunismo."
                        windowWidth={800}
                        body="Participe dessa campanha importante"
                        onClick={handleShared}
                    >
                        <Image
                            src="/social-buttons/email.svg"
                            width={40}
                            height={40}
                            alt="Compartilhe por email"
                        />
                    </EmailShareButton>

                    <Popover>
                        <PopoverTrigger>
                            <LinkBox
                                as="cards"
                                m="0"
                                p="0"
                                title={hasCopied ? "Link copied" : "Copy the link"}
                                onClick={onCopy}
                            >
                                <Image
                                    src={hasCopied ? "/social-buttons/link-clicked.svg" : "/social-buttons/link.svg"}
                                    width={40}
                                    height={40}
                                    alt="Compartilhe"

                                />
                            </LinkBox>
                        </PopoverTrigger>
                        <PopoverContent color="white" bg="blue.base" borderColor="blue.base">
                            <PopoverCloseButton />
                            <PopoverHeader fontWeight="bold" border="0">Link copiado!</PopoverHeader>
                        </PopoverContent>
                    </Popover>
                </HStack>
            }
        </Flex >
    )
}