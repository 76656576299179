import { Box, Container, Flex, Heading, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { BasicData } from './BasicData';
import styles from "./Form.module.css";

export function Form() {
    return (
        <Container m={0} textAlign={"center"} overflow="hidden" maxW={"100%"} p={0}>
            <Wrap spacing={"10"}>
                <WrapItem w={'100%'}>
                    <Flex flexDir={"column"} w={"100%"}>
                        <Heading fontFamily={"Roboto Slab"} fontWeight={500} w={"100%"}>
                            Quanto você JÁ rezou?
                        </Heading>
                        <Text mt={{ sm: 3, md: 3, lg: 2 }} color="gray.300">
                            Preencha o formulário de acordo com as Ave-Marias que você já rezou.
                        </Text>
                    </Flex>
                </WrapItem>
                <WrapItem w={"100%"}>
                    <Box
                        bg="white"
                        borderRadius="lg"
                        m={"0 auto"}
                        color="#0B0E3F"
                        className={styles.Form}
                        w={"100%"}
                    >
                        <BasicData />
                    </Box>
                </WrapItem>
            </Wrap>
        </Container>
    );
}