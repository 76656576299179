import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import countries from "./../contries.json";

export function Country({ country, setCountry }) {
  const { register, formState: { errors } } = useFormContext()

  const countriesSorted = countries.sort(function (a, b) {
    const nameA = a.names.portuguese.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const nameB = b.names.portuguese.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return +1
    }
    return 0
  })

  return <FormControl id="country" isInvalid={errors.country}>
    <FormLabel mb={0}>Informe seu país</FormLabel>
    <Select
      placeholder="Selecione seu país"
      size={"md"}
      name={"country"}
      value={country}
      {...register("country", {
        required: "Campo obrigatório",
        onChange: (e) => setCountry(e.target.value)
      })}
    >
      {
        countriesSorted.map((item) => (
          <option
            key={item.names.english}
            value={item.names.english}
          >
            {item.names.portuguese}
          </option>
        ))
      }
    </Select>
    <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
  </FormControl>
}