import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { useFeature } from "@growthbook/growthbook-react";
import { useFormContext } from "react-hook-form";

export function City({ city, setCity }) {
  const { register, formState: { errors } } = useFormContext()

  const addressWithZipcode = useFeature("address-with-zipcode").on;

  return <FormControl
    id="city"
    isInvalid={errors.city}
    display={addressWithZipcode && 'none'}
  >
    <FormLabel mb={0}>Informe sua cidade</FormLabel>
    <Input
      name={"city"}
      type="text"
      size="md"
      value={city}
      placeholder="Belo Horizonte"
      {...register("city", {
        required: "Campo obrigatório",
        minLength: {
          value: 2,
          message: "Digite pelo menos 2 caracteres"
        },
        maxLength: {
          value: 30,
          message: "Digite no máximo 30 caracteres"
        },
        onChange: (e) => { setCity(e.target.value) },
      })}
    />
    <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
  </FormControl>
}