import { Box, Container, Heading, Text } from "@chakra-ui/react";
import Image from "next/image";
import { Form } from "../components/Form/";
import { Meta } from "../components/Meta";
import { Stats } from "../components/Stats";

import styles from "../styles/Home.module.css";

// import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
// import nookies from 'nookies';
import { useEffect } from "react";
import coverPic from "../../public/logo-campanha.jpg";
// import { prisma } from "../lib/prisma";
import { CallToActionShare } from "./../components/CallToActionShare";

//@ts-ignore
export default function Home(props) {
  const router = useRouter();
  useEffect(() => {
    router.prefetch("/agendar");
  }, []);

  const prayerText = [
    "Ó Rainha de Fátima, nesta hora de tantos perigos para as nações cristãs, afastai delas o flagelo do comunismo ateu. Não permitais que consiga instaurar-se, em tantos países nascidos e formados sob o influxo sagrado da Civilização Cristã, o regime comunista, que nega todos os Mandamentos da Lei de Deus.",
    "Para isto, ó Senhora, conservai vivo e aumentai o repúdio que o comunismo encontrou em todas as camadas sociais dos povos do Ocidente cristão. Ajudai-nos a ter sempre presente que:",
    "<strong>1°) O Decálogo nos manda</strong> “amar a Deus sobre todas as coisas”, “não tomar seu Santo Nome em vão” e “guardar os domingos e festas de preceito”. Mas o comunismo ateu tudo faz para extinguir a Fé, levar os homens à blasfêmia e criar obstáculos à normal e pacífica celebração do culto.",
    "<strong>2°) O Decálogo manda</strong> “honrar pai e mãe”, “não pecar contra a castidade” e “não desejar a mulher do próximo”. Mas o comunismo deseja romper os vínculos entre pais e filhos; quer entregar ao Estado a educação dos filhos; nega o valor da castidade; e ensina que o casamento pode ser dissolvido por qualquer motivo, pela mera vontade de um dos cônjuges.",
    "<strong>3°) O Decálogo manda</strong> “não furtar” e “não cobiçar as coisas alheias”. Mas o comunismo nega a propriedade privada e a sua importante função social.",
    "<strong>4°) O Decálogo manda</strong> “não matar”. Mas o comunismo emprega a guerra de conquista como meio de expansão ideológica, promove revoluções e crimes em todo o mundo.",
    "<strong>5°) O Decálogo manda</strong> “não levantar falso testemunho”. Mas o comunismo usa sistematicamente a mentira como arma de propaganda.",
    "Fazei que, tolhendo resolutamente os passos à infiltração comunista, todos os povos do Ocidente cristão possam contribuir para que se aproxime o dia da gloriosa vitória que predissestes em Fátima, com estas palavras tão cheias de esperança e doçura: “Por fim, o meu Imaculado Coração triunfará”.",
  ];
  return (
    <>
      <Meta
        title={"Reze contra o comunismo"}
        description={"10 milhões de Ave-Marias pelo Brasil"}
        socialTitle={"Reze pelo Brasil contra o comunismo"}
        pageType={"website"}
        image={"https://rezecontraocomunismo.org.br/logo-campanha.jpg"}
        createdAt={new Date()}
      />
      <Container
        centerContent={true}
        m={"0 auto"}
        mt={"2rem"}
        maxW='90%'
        position='relative'
        pb={8}
        px='0'
      >
        <Heading
          as={"h1"}
          fontFamily={"Roboto Slab, serif"}
          fontWeight={"400"}
          fontSize={"4xl"}
          my={8}
          w='100%'
          color={"gray.700"}
          sx={{
            "@media screen and (max-width: 720px)": {
              fontSize: "2xl",
              mt: "0",
              mb: "0",
              textAlign: "center",
            },
          }}
        >
          10 milhões de Ave-Marias contra o comunismo
        </Heading>
        <Box
          as='main'
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection='row-reverse'
          w='100%'
          sx={{
            "@media screen and (max-width: 720px)": {
              maxW: "100%",
              flexDirection: "column",
              p: "0",
            },
          }}
        >
          <Box
            maxW='45%'
            w='container.xl'
            marginTop='0'
            marginLeft={4}
            sx={{
              "@media screen and (max-width: 720px)": {
                maxW: "100%",
                marginTop: 4,
                marginLeft: "0",
              },
            }}
          >
            <Box
              bg='blue.base'
              color='white'
              mb={8}
              p={4}
              borderBottomRadius={"lg"}
              position='sticky'
              top='0'
            >
              <Form />
            </Box>
          </Box>
          <Box
            marginTop='0'
            marginRight={4}
            maxW='55%'
            w='container.xl'
            sx={{
              "@media screen and (max-width: 720px)": {
                marginTop: 4,
                marginRight: "0",
                maxW: "100%",
              },
            }}
          >
            <Box className={styles.MainImageContainer} zIndex='-1'>
              <Image
                src={coverPic}
                alt={
                  "Mapa do Brasil com Cristo Redentor ao fundo. Rosário sobrepondo o símbolo do comunismo"
                }
                priority={true}
                layout={"responsive"}
                placeholder={"blur"}
                blurDataURL={"/logo-campanha.jpg"}
                className={styles.MainImage}
              />
            </Box>
            <Box
              /*className={styles.Text}*/ sx={{
                textIndent: "2em",
              }}
            >
              <Heading
                as='h2'
                fontSize={"2xl"}
                mt={8}
                mb={4}
                textAlign='center'
                sx={{
                  "@media screen and (max-width: 720px)": {
                    fontSize: "lg",
                  },
                }}
              >
                Oração a Nossa Senhora de Fátima
              </Heading>
              {prayerText.map((paragraph, index) => (
                <Text
                  fontSize='lg'
                  mt={2}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              ))}
              <Text
                textAlign='right'
                my={8}
                color='gray.700'
                fontWeight={"bold"}
                fontSize='lg'
              >
                ― Plinio Corrêa de Oliveira
              </Text>
            </Box>
            <Stats />
          </Box>
        </Box>
        <Box mt={16}>
          <CallToActionShare />
        </Box>
        {/* <Faq /> */}
      </Container>
    </>
  );
}

// export const getServerSideProps: GetServerSideProps = async (ctx) => {
//     const { 'rezecontraocomunismo@db': cookie } = nookies.get(ctx)
//     if (cookie !== undefined) {
//         return {
//             props: {}
//         }
//     }
//     // throw new Error('Se a pessoa tiver um utm_cadastrado não gerar um novo para ela')

//     const { url } = ctx.req
//     const urlSplitted = url?.split('?')
//     let utmsSplitted: any = []
//     if (urlSplitted !== undefined && urlSplitted?.length > 1) {
//         utmsSplitted = urlSplitted !== undefined && urlSplitted[1].split('&')
//     }

//     let utmsFormatted: any = []
//     if (Array.isArray(utmsSplitted)) {
//         utmsSplitted.forEach((utm: any) => {
//             const data = utm?.split('=')
//             utmsFormatted[data[0].toLowerCase()] = data[1] || ''
//         })
//     }

//     const utm = await prisma.utm.create({
//         data: {
//             utmCampaign: utmsFormatted['utm_campaign'] || '',
//             utmContent: utmsFormatted['utm_content'] || '',
//             utmMedium: utmsFormatted['utm_medium'] || '',
//             utmSource: utmsFormatted['utm_source'] || '',
//             contactId: 0,
//             fullUrl: url || '',
//             isPrayed: false,
//             isScheduled: false,
//             IsDonated: false,
//             isShared: false,
//             pageLeave: 'home',
//             ab: ''
//         }
//     })

//     nookies.set(ctx, 'rezecontraocomunismo@db', JSON.stringify({ utm_id: utm.id }), {
//         maxAge: 60 * 60 * 24 * 30 * 6, // 6 months
//         path: '/'
//     })

//     return {
//         props: {}
//     }
// }
