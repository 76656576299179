import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { useState } from "react"
import { useFormContext } from "react-hook-form"

export function FirstName({ value }) {
  const { register, formState: { errors } } = useFormContext()
  const [name, setName] = useState(value || '')
  return <FormControl isInvalid={errors.first_name}>
    <FormLabel mb={0}>Primeiro nome</FormLabel>
    <Input
      type="text"
      size="md"
      name={"first_name"}
      value={name}
      placeholder={"João"}
      {...register("first_name", {
        required: "Campo obrigatório",
        minLength: {
          value: 2,
          message: "Digite pelo menos 2 caracteres"
        },
        maxLength: {
          value: 30,
          message: "Digite no máximo 30 caracteres"
        },
        onChange: (e) => { setName(e.target.value) }
      })}
    />
    <FormErrorMessage>{errors.first_name?.message}</FormErrorMessage>
  </FormControl>
}