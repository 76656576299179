import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Spinner
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import InputMask from 'react-input-mask';

export function Zipcode({ value, uf, setUf, city, setCity }) {
  const { register, clearErrors, setError, setValue, formState: { errors } } = useFormContext()

  const [zipcode, setZipcode] = useState(value || '')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCepWrong, setIsCepWrong] = useState(false)

  useEffect(() => {
    const getAddressData = async () => {
      setIsSubmitting(true)
      const zipcodeFormatted = zipcode.replace('.', '').replace('-', '')
      axios({
        method: 'GET',
        url: `https://brasilapi.com.br/api/cep/v1/${zipcodeFormatted}`
      }).then(res => {
        clearErrors('zipcode')
        setValue('city', res.data.city)
        setUf(res.data.state)
        setCity(res.data.city)
        setIsSubmitting(false)
        setIsCepWrong(false)
      }).catch(error => {
        setIsCepWrong(true)
        setIsSubmitting(false)
        setError('zipcode', { type: 'custom', message: "CEP não encontrado" })
      })
    }

    if (zipcode.length < 10) {
      setIsSubmitting(false)
      return
    }

    if (zipcode.length >= 10) {
      getAddressData()
    }
  }, [zipcode, setZipcode])
  return <FormControl id="zipcode" isInvalid={errors.zipcode}>
    <FormLabel mb={0}>Informe o CEP da sua residência</FormLabel>
    <Input
      mask={"99.999-999"}
      maskChar=''
      as={InputMask}
      type="text"
      size="md"
      value={zipcode}
      placeholder="99.999-999"
      {...register("zipcode", {
        required: "Campo obrigatório",
        minLength: {
          value: 10,
          message: "Digite seu CEP corretamente"
        },
        onChange: (e) => { setZipcode(e.target.value) },
      })}
    />
    <FormErrorMessage>{errors.zipcode?.message}</FormErrorMessage>
    <FormHelperText textAlign="left">
      {
        isSubmitting ?
          <Spinner w={4} h={4} /> :
          zipcode.length === 10 && !isCepWrong && city + ', ' + uf
      }

    </FormHelperText>
  </FormControl>
}