import { Button, FormControl, FormLabel, HStack, Input, useNumberInput } from "@chakra-ui/react"
import { useEffect } from "react"

export function HailMary({ hailMary, setHailMary }) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    min: 3,
    max: 1000,
    defaultValue: hailMary,
    precision: 0
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  useEffect(() => {
    setHailMary(input.value)
  }, [input])

  return <FormControl>
    <FormLabel textAlign={"center"}>
      Informe a quantidade de Ave-Marias que você JÁ rezou
    </FormLabel>
    <HStack maxW={"250px"} m={"0 auto"}>
      <Button {...dec} colorScheme={"red"}>-</Button>
      <Input {...input} autoComplete={"off"} />
      <Button {...inc} colorScheme={"red"}>+</Button>
    </HStack>
  </FormControl>
}