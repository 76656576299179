import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { useState } from "react"
import { useFormContext } from "react-hook-form"

export function LastName({ value }) {
  const { register, formState: { errors } } = useFormContext()
  const [lastName, setLastName] = useState(value || '')

  return <FormControl isInvalid={errors.last_name}>
    <FormLabel mb={0}>Último nome</FormLabel>
    <Input
      type="text"
      size="md"
      name={"last_name"}
      value={lastName}
      placeholder={"da Silva"}
      {...register("last_name", {
        required: "Campo obrigatório",
        minLength: {
          value: 2,
          message: "Digite pelo menos 2 caracteres"
        },
        maxLength: {
          value: 30,
          message: "Digite no máximo 30 caracteres"
        },
        onChange: (e) => { setLastName(e.target.value) }
      })}
    />
    <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
  </FormControl>
}