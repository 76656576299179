import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { useState } from "react"
import { useFormContext } from "react-hook-form"

export function Email({ value }) {
  const { register, formState: { errors } } = useFormContext()
  const [email, setEmail] = useState(value || '')
  return <FormControl isInvalid={errors.email}>
    <FormLabel mb={0}>Informe seu e-mail</FormLabel>
    <Input
      placeholder="joao@exemplo.com"
      value={email}
      {...register("email", {
        required: "Campo obrigatório",
        pattern: {
          value: /^\S+@\S+$/i,
          message: "Digite um e-mail válido"
        },
        onChange: (e) => { setEmail(e.target.value); }
      })}
      type="text"
      size="md"
    />
    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
  </FormControl>
}